<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div class="ml-2">
                    <div class="text-xl text-primary font-bold">
                      {{ $t("funding_or_scholarship") }}
                    </div>
                    <Divider></Divider>
                    <div class="text-purple-500">
                      <div class="font-bold text-yellow-500 mb-2">
                        {{ $t("instructions") }}
                      </div>
                      <ul>
                        <li>{{ $t("all_fields_marked_are_mandatory") }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="page-section">
      <div class="container">
        <div class="grid">
          <div class="col-12 md:col-12 sm:col-12 comp-grid">
            <div>
              <template v-if="!loading">
                <div class="grid">
                  <div class="col-12">
                    <form ref="observer" tag="form" @submit.prevent="startRecordUpdate()">
                      <div class="card nice-shadow-5">
                        <div class="mb-4 text-blue-500 font-bold text-md">
                          {{ $t("undertaking") }}
                        </div>

                        <div class="grid mb-2">
                          <div class="col-12 md:col-12">
                            <div class="mb-2 font-bold text-sm">
                              Select sponsorship *
                            </div>
                            <div class="">
                              <div class="field-radiobutton" v-for="option of $menus.sponsorTypeItems"
                                :key="option.value">
                                <RadioButton :class="getErrorClass('sponsor')" :id="option.value" name="ctrlsponsor"
                                  :value="option.value" v-model="formData.sponsor" />
                                <label>{{ option.label }} </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div v-if="formData.sponsor == this.other" class="mb-4">
                          <div class="grid">
                            <div class="col-12 md:col-12">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("name_of_sponsor") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlsponsor_name" v-model.trim="formData.sponsor_name"
                                  :label="$t('name_of_sponsor')" type="text" :placeholder="$t('name_of_sponsor')"
                                  class="w-full" :class="getErrorClass('sponsor_name')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-12">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("full_address_of_sponsor") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlsponsor_address" v-model.trim="formData.sponsor_address"
                                  :label="$t('full_address_of_sponsor')" type="text"
                                  :placeholder="$t('full_address_of_sponsor')" class="w-full"
                                  :class="getErrorClass('sponsor_address')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div class="col-12 md:col-12">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("sponsor_telephone") }} *
                              </div>
                              <div class="grid">
                                <div class="col-12 md:col-4">
                                  <div class="">
                                    <!-- <div class="field mb-0"> -->
                                    <Dropdown class="w-full" :class="getErrorClass(
                                      'sponsor_telephone_dial_code'
                                    )
                                      " optionLabel="dial_code" optionValue="value"
                                      ref="ctrlsponsor_telephone_dial_code" :filter="true"
                                      :filterFields="['name', 'dial_code']" v-model="formData.sponsor_telephone_dial_code
                                        " :options="$menus.dial_codes_list" :label="$t('dial_code')"
                                      :placeholder="$t('dial_code')">
                                      <template #option="slotProps">
                                        <div class="flex align-items-center">
                                          <div>
                                            <div>
                                              {{ slotProps.option.name }}
                                            </div>
                                            <div v-if="slotProps.option.name" class="text-sm text-500">
                                              {{ slotProps.option.code }}
                                              {{ slotProps.option.dial_code }}
                                            </div>
                                          </div>
                                        </div>
                                      </template>
                                    </Dropdown>
                                    <!-- </div> -->
                                  </div>
                                </div>
                                <div class="col-12 md:col-8">
                                  <div class="">
                                    <!-- <div class="field mb-0"> -->
                                    <InputText ref="ctrlsponsor_telephone" v-model.trim="formData.sponsor_telephone"
                                      :label="$t('sponsor_telephone')" type="text" :placeholder="$t('sponsor_telephone')"
                                      pattern="^[0-9]+$" class="w-full" maxlength="10" minlength="8" :class="getErrorClass('sponsor_telephone')
                                        " v-tooltip.top.focus="'Eg: 0209013836'">
                                    </InputText>
                                    <!-- </div> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 md:col-12">
                              <div class="mb-2 font-bold text-sm">
                                {{ $t("sponsor_email") }} *
                              </div>
                              <div class="">
                                <!-- <div class="field mb-0"> -->
                                <InputText ref="ctrlsponsor_email" v-model.trim="formData.sponsor_email"
                                  :label="$t('sponsor_email')" type="email" :placeholder="$t('sponsor_email')"
                                  class="w-full" maxlength="40" :class="getErrorClass('sponsor_email')">
                                </InputText>
                                <!-- </div> -->
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="grid">
                          <div class="col-12 md:col-12">
                            <div class="mb-2 font-bold text-sm">
                              {{
                                $t(
                                  "considered_for_scholarship"
                                )
                              }}
                              *
                            </div>
                            <div class="">
                              <div class="field-radiobutton" v-for="option of $menus.yes_or_noItems" :key="option.value">
                                <RadioButton :class="getErrorClass('consider_scholarship')" :id="option.value"
                                  name="ctrlconsider_scholarship" :value="option.value"
                                  v-model="formData.consider_scholarship" />
                                <label>{{ option.label }} </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="formData.consider_scholarship == yes" class="card nice-shadow-5">
                        <div class="mb-1 text-blue-500 font-bold text-md">
                          {{ $t("apply_for_funding_or_scholarship") }}
                        </div>
                        <small class="text-xs text-primary">
                          {{ $t("click_on_icon_at_the_bottom_to_add_more") }}
                        </small>

                        <div class="grid">
                          <div class="col-12 md:col-12 my-0 py-0">
                            <list-applicantselectedgrants-page is-sub-page ref="applicantSelectedGrantsListPage"
                              :limit="10" :show-header="false" :show-breadcrumbs="false" :show-footer="false"
                              :paginate="false" v-if="ready">
                            </list-applicantselectedgrants-page>
                          </div>
                          <div class="col-12 md:col-12">
                            <div class="mt-3">
                              <applicantselectedgrants-page is-sub-page ref="applicantselectedgrantsForm"
                                :show-submit-button="false" :applicantselectedgrants="formData.applicant_selected_grants"
                                v-if="ready"></applicantselectedgrants-page>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!--[form-content-end]-->
                      <div v-if="showSubmitButton" class="text-center my-3">
                        <Button type="submit" :label="$t('save_and_continue')" icon="pi pi-send" :loading="saving" />
                      </div>
                    </form>
                    <slot :submit="submit" :saving="saving"></slot>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="p-3 text-center">
                  <ProgressSpinner style="width: 50px; height: 50px" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import applicantselectedgrantsPage from "../applicants/fundingdata_applicant_selected_grants.vue";
import listApplicantselectedgrantsPage from "../applicant_selected_grants/list.vue";
import { PageMixin } from "../../mixins/page.js";
import { ApplicantEditPageMixin } from "../../mixins/applicanteditpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  required, email, requiredIf
} from "@vuelidate/validators";
import { i18n } from "../../services/i18n.js";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "fundingdataApplicantsPage",
  components: { applicantselectedgrantsPage, listApplicantselectedgrantsPage },
  mixins: [PageMixin, ApplicantEditPageMixin],
  props: {
    pageName: {
      type: String,
      default: "applicants",
    },
    idName: {
      type: String,
      default: "applicant_id",
    },
    routeName: {
      type: String,
      default: "applicantsfundingdata",
    },
    pagePath: {
      type: String,
      default: "applicants/fundingdata",
    },
    apiPath: {
      type: String,
      default: "applicants/fundingdata",
    },
    pageIndex: { type: Number, default: 5 },
  },
  data() {
    return {
      formData: {
        consider_scholarship: "",
        sponsor: "",
        sponsor_name: "",
        sponsor_address: "",
        sponsor_telephone: "",
        sponsor_telephone_dial_code: "",
        sponsor_email: "",
      },
      submitted: false,
      yes: "Yes",
      no: "No",
      other: "Other"
    };
  },
  computed: {
    ...mapGetters("applicants", ["fundingDataRecord"]),
    pageTitle: {
      get: function () {
        return this.$t("edit_applicants");
      },
    },
    apiUrl: function () {
      if (this.id) {
        return this.apiPath + "/" + encodeURIComponent(this.id);
      }
      return this.apiPath;
    },
  },
  validations() {
    let formData = {
      consider_scholarship: { required },
      sponsor: { required },
      sponsor_name: {
        required: requiredIf(() => {
          const esit = this.formData.sponsor == this.other;
          return esit;
        }),
      },
      sponsor_address: {
        required: requiredIf(() => {
          const esit = this.formData.sponsor == this.other;
          return esit;
        }),
      },
      sponsor_telephone: {
        required: requiredIf(() => {
          const esit = this.formData.sponsor == this.other;
          return esit;
        }),
      },
      sponsor_telephone_dial_code: {
        required: requiredIf(() => {
          const esit = this.formData.sponsor == this.other;
          return esit;
        }),
      },
      sponsor_email: {
        required: requiredIf(() => {
          const esit = this.formData.sponsor == this.other;
          return esit;
        }),
      },
    };
    return { formData };
  },
  methods: {
    ...mapActions("applicants", ["updateFundingData", "fetchFundingData"]),
    load: function () {
      var url = this.apiUrl;
      this.loading = true;
      this.ready = false; // hide other components until main page is ready
      this.fetchFundingData(url).then(
        (response) => {
          this.loading = false;
          this.ready = true;
          // this.fundingDataRecord = response.data;
          this.formData = response.data;
          this.updateFormFields();
        },
        (response) => {
          this.resetForm();
          this.loading = false;
          this.showPageRequestError(response);
        }
      );
    },
    async startRecordUpdate() {
      this.submitted = true;
      const isFormValid = await this.v$.$validate();
      let payload = this.normalizedFormData();
      payload.applicant_selected_grants = this.$refs.applicantselectedgrantsForm?.arrFormData;
      let errors = this.$refs.applicantselectedgrantsForm?.validateForm();

      if (this.formData.consider_scholarship == this.no) {
        errors = true;
      }

      if (!isFormValid || !errors) {
        this.flashMsg(
          this.$t("please_complete_the_form"),
          this.$t("form_is_invalid"),
          "error"
        );
        return;
      }

      this.saving = true;
      let id = this.id;
      let url = this.apiUrl;
      let data = { id, url, payload };
      this.updateFundingData(data).then(
        (response) => {
          this.saving = false;
          this.flashMsg(
            this.$t("information_was_updated"),
            this.$t("form_saved_successfully")
          );
          this.resetForm();
          this.closeDialogs(); // close page dialog that if opened
          this.$emit("next", this.pageIndex);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array
    },
    resetForm() {
      //reset form fields value
      this.formData = {
        consider_scholarship: "",
      };
      //raise event to reset other custom form components
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      if (this.v$.formData[field].$invalid && this.submitted) {
        return "p-invalid";
      }
      return "";
    },
  },
  watch: {},
  async mounted() {
    this.load();
  },
  async created() { },
};
</script>
<style scoped>
li {
  margin-top: 0.5em;
  line-height: 1.4em;
}
</style>